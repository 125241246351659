import { Button } from '@/components/ui/button'
import { getUserInfo, logoutUtil } from '@/utils/api/user';
import { UpdateIcon } from '@radix-ui/react-icons';
import { MobileButtonSheet } from '../customized-ui/sheet/menu-button';
import { useEffect, useMemo } from 'react';
import { cn } from '@/lib/utils';
import { P1 } from '../customized-ui/typography/p1';
import { PiSignOut } from 'react-icons/pi';
import useDialogStore from '@/store/useDialogStore';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import useAuthStore from '@/store/useAuthStore';

const Navbar = () => {
  const { showDialog } = useDialogStore();
  const isAdmin = window.location.pathname.includes('/admin')
  const { user, login } = useAuthStore();

  useEffect(() => {
    async function handleGetUserInfo() {
      if (!user) {
        const userData = await getUserInfo();
        login(userData);
      }
    }
    handleGetUserInfo();
  }, [login, user]);

  const onOpenCalibrate = () => {
    showDialog('CALIBRATE', {
      title: 'Update Profile',
    })
  }

  const onViewProfile = () => {
    window.location.href = process.env.REACT_APP_PROFILE_URL || '/';
  }

  const onSignOut = async () => {
    await logoutUtil();
    window.location.href = process.env.REACT_APP_LOGOUT_REDIRECT_URL || '/';
  }
  
  const navItems = useMemo(() => 
    isAdmin
      ? [
        { title: 'Talent Search', link: '/admin' },
        { title: 'Insight', link: '/admin/insight' },
        { title: 'Generate GES Report', link: '/admin/report' }
      ]
      : [
        { title: 'CV 360', link: '/talent/resume' },
        { title: 'Job 360', link: '/'},
        { title: 'FAQ', link: process.env.REACT_APP_FAQ_URL || '', target: '_blank'}
      ],
  [isAdmin]
  );

  const mobileNavItems = useMemo(() => [
    { title: 'Job Search', link: '/' },
    { title: 'Message Box', link: '/talent/message' },
    { title: 'Customize CV', link: '/talent/resume' },
    { title: 'Sign Out', action: onSignOut }
  ], []);

  return (
    <div className='h-16 bg-[#FFFFFF] flex justify-between items-center px-4'>
      <div className='flex justify-start items-center gap-2'>
        <a href={process.env.REACT_APP_HOME_URL} className='flex items-center gap-x-2'>
          <img src="/cuhk_logo.png" alt="cuhk logo" className='h-10 object-contain' />
        </a>
        <div className='hidden md:flex'>
          {navItems.map((item, index) => (
            <a key={index} href={item.link} className='flex items-center' target={item.target}>
              <h1 className={cn('text-neutral-800 hover:text-primary-800 hover:underline text-sm mr-4', window.location.pathname.split('?')[0] === item.link ? 'text-primary-800 underline' : '')}>{item.title}</h1>
            </a>
          ))}
        </div>
      </div>
      <div className='gap-4 hidden md:flex items-center'>
        {!isAdmin && (
          <>
            <Button className='flex gap-x-2 items-center bg-primary-800 hover:bg-primary-900' onClick={onOpenCalibrate}>
              <UpdateIcon />
              <P1>
                  Refresh profile updates
              </P1>
            </Button>
            <Button type='button' variant='link' onClick={onViewProfile} className='text-primary-800 hover:text-primary-900 underline'>
              <P1>
                View Profile
              </P1>
            </Button>
          </>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Avatar>
              <AvatarFallback className='bg-primary-800 text-white'>{user?.name?.charAt(0) || 'U'}</AvatarFallback>
            </Avatar>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={onSignOut}>
              <PiSignOut className="mr-2 h-4 w-4" />
              <span>Sign Out</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className='md:hidden'>
        <MobileButtonSheet>
          <div className="flex flex-col gap-4 my-6">
            {mobileNavItems.map((item, index) => (
              <Button
                key={index}
                variant='outline'
                onClick={() => {
                  if (item.link) {
                    window.location.replace(item.link);
                  } else if (item.action) {
                    item.action();
                  }
                }}
                type='button'
                className='w-full py-2 px-4 text-slate-900 dark:text-slate-50 hover:bg-slate-100 dark:hover:bg-slate-800'>
                {item.title}
              </Button>
            ))}
          </div>
        </MobileButtonSheet>
      </div>
    </div>
  )
}

export default Navbar